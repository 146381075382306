.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
}

.logo {
    font-size: 28px;
    font-weight: 700;
    color: #2c3e50;
}

.nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-links li:not(:last-child) {
    margin-right: 30px;
}

.nav-links a {
    text-decoration: none;
    color: #2c3e50;
    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
}

.nav-links a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #3498db;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.nav-links a:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

.nav-links .active {
    font-weight: 700;
}

.language-dropdown {
    position: relative;
}

.language-dropdown-button {
    color: #2c3e50;
    padding: 8px 16px;
    font-size: 16px;
    box-shadow: none;
}


.language-dropdown-content {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1;
    min-width: 180px;
    padding: 8px 0;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
}

.language-dropdown-content a {
    display: flex;
    align-items: center;
    color: #2c3e50;
    font-size: 16px;
    padding: 8px 16px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.language-dropdown-content a:hover {
    background-color: #f6f6f6;
}

.language-dropdown-content .flag-icon {
    margin-right: 8px;
}

.language-dropdown-content img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: none;
    padding: 0;
    vertical-align: middle;
}